<template>
  <div id="container" ref="map" :style="styles"></div>
</template>

<script>
export default {
  data () {
    return {
      styles: {
        width: 100 + 'vw',
        height: 100 + 'vh'
      },
      map: null,
      marker: null,
      label: null,
      center: null
    }
  },
  watch: {
    windowHeight (val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
      console.log("titlelist：", this.titlelist);
      this.refreshStyle()
    },
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
      console.log("titlelist：", this.titlelist);
      this.refreshStyle()
    }
  },
  computed: {
    domain () {
      return this.$store.state.tab.domain
    },
    domain443 () {
      return this.$store.state.tab.domain443
    },
    titlelist () {
      return this.$store.state.user.titlelist
    },
    windowWidth () {
      return this.$store.state.tab.windowWidth
    },
    windowHeight () {
      return this.$store.state.tab.windowHeight
    }
  },
  methods: {
    initMap () {
      this.center = new TMap.LatLng(30.466922, 114.471923);//设置中心点坐标
      //初始化地图
      this.map = new TMap.Map(this.$refs.map, {
        center: this.center,
        zoom: 15
      });

      //初始化marker
      this.marker = new TMap.MultiMarker({
        id: 'marker-layer',
        map: this.map,
        styles: {
          "marker": new TMap.MarkerStyle({
            "width": 150,
            "height": 50,
            "anchor": { x: 80, y: 40 },
            "src": 'https://kecheng-diancan-1309444200.cos.ap-nanjing.myqcloud.com/kecheng-diancan/logo.png'
            //"src": '../logo.png'
          })
        },
        geometries: [{
          "id": 'demo',
          "styleId": 'marker',
          "position": new TMap.LatLng(30.466922, 114.471923),
          "properties": {
            "title": "marker"
          }
        }]
      });

      this.label = new TMap.MultiLabel({
        id: 'label-layer',
        map: this.map, //设置折线图层显示到哪个地图实例中
        //文字标记样式
        styles: {
          'label': new TMap.LabelStyle({
            'color': '#3777FF', //颜色属性
            'size': 30, //文字大小属性
            'offset': { x: 0, y: -60 }, //文字偏移属性单位为像素
            'angle': 0, //文字旋转属性
            'alignment': 'center', //文字水平对齐属性
            'verticalAlignment': 'middle' //文字垂直对齐属性
          })
        },
        //文字标记数据
        geometries: [{
          'id': 'label_1', //点图形数据的标志信息
          'styleId': 'label', //样式id
          'position': this.center, //标注点位置
          'content': '武汉总部', //标注文本
        }]
      });

      //Map实例创建后，通过on方法绑定点击事件
      this.map.on("click", (evt) => {
        var lat = evt.latLng.getLat().toFixed(6);
        var lng = evt.latLng.getLng().toFixed(6);
        console.log("您点击的的坐标是：" + lat + "," + lng);
        this.map.setCenter(new window.TMap.LatLng(lat, lng));
      })
      console.log(this.map)
    },
    refreshStyle () {
      this.styles.width = 95 + 'vw'
      this.styles.height = 85 + 'vh'
    }
  },
  mounted () {
    this.refreshStyle()
    this.initMap()
  }
}
</script>
